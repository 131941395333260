import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

const Process = styled(Container)`
  margin-bottom: 64px;
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 16px;
  }
  p {
    ${props => props.theme.main.fonts.body}
  }
  .nav-tabs {
    display: none;
    margin-left: -15px;
    margin-bottom: 8px;
    border: none;
    .nav-item {
      .nav-link {
        ${props => props.theme.main.fonts.displayMedium}
        border: none;
        padding: 15px;
        cursor: pointer;
        &.active {
          color: ${props => props.theme.main.colors.orange.regular};
          cursor: default;
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    ${breakpoint('md')`
      display: flex;
    `}
  }
  .tab-content {
    .tab-pane {
      display:block;
      ${breakpoint('md')`
        display: none;
        &.active {
          display: block;
        }
        h4 {
          display: none;
        }
      `}
      h4 {
        ${props => props.theme.main.fonts.heading}
        color: ${props => props.theme.main.colors.orange.regular};
        margin-bottom: 16px;
      }
      p {
        ${props => props.theme.main.fonts.body}
      }
    }
  }
`;

export default () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  return (
    <Process>
      <h3>How we do it</h3>
      <p>We like to involve our clients from the get go. The better we are at establishing protocol with our client, the better the project or product will meet their needs.</p>
      <Nav tabs>
        <NavItem>
          <NavLink onClick={() => { toggle('1'); }} className={(activeTab === '1') ? 'active' : null}>
            Minimize Overhead
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => { toggle('2'); }} className={(activeTab === '2') ? 'active' : null}>
            Prioritize Quality
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={() => { toggle('3'); }} className={(activeTab === '3') ? 'active' : null}>
            A Community Focused Agency
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <h4>Minimize Overhead</h4>
              <p>
                In the spirit of agile process, we are developers, analysts and to an extent, our teams manage themselves. Leadership helps coordinate, plan releases, testing, and make sure that communication happens effectively.
              </p>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <h4>Prioritize Quality</h4>
              <p>
                We emphasize quality. We much rather build fewer components or features that provide value rather than make a whole pile of stuff that the only sort of works that no one wants to use. Everyone's better off with quality products that work, instead of feature bloat that doesn't consistently function, or worse breaks and corrupts data in the process.
              </p>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <h4>A Community Focused Agency</h4>
              <p>We believe being an integral part of your community is as equally as important to being a successful business. To uphold the title of community-focused, we work with local universities and technical colleges and hire and train coop students. We focus on providing real-world experience for them. Not just a data entry gig, or sorting through side projects no one wants to do.</p>
              <p>We love open source software and the community around that. On our roadmap is to select a community project and contribute to it. We couldn't be where we are without an open source, so we want to make sure we give back. We support local businesses, locally source talent when we can, and we support local clubs, charities, and other relevant organizations. Support non-profit organizations with some of their software development related needs.</p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Process>);
}